import { useContext, useState } from "react";

import { Stack } from "@mui/material";
import { FormType } from "@simplyk/common";

import { FrontendFormContext } from "../../../../../contexts/FrontendFormContext";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { useFormV2Context } from "../../../context/FormV2Context";

import { AmplitudeEvents } from "@/constants/amplitude";
import { Checkbox } from "@/design-system/Checkbox/Checkbox";
import { TextField } from "@/design-system/TextField/TextField";
import { isEmail } from "@/helpers/validators";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";

export const InHonour = () => {
  const { t } = useTranslate();
  const { logAmplitudeEventCallback } = useAmplitude();
  const { isEmbed } = useContext(FrontendFormContext);
  const { formObject, form, formCountry } = useFormV2Context(FormType.DonationForm);
  const { errors } = form.formState;

  // State for tracking if donation is in honor of someone
  const [isInHonour, setIsInHonour] = useState(false);
  const toggleIsInHonour = () => {
    setIsInHonour((prev) => !prev);
  };

  // State for tracking if we should notify someone about the donation
  const [notifySomeone, setNotifySomeone] = useState(false);
  const toggleNotifySomeone = () => {
    setNotifySomeone((prev) => !prev);
  };

  const amplitudePayload = {
    isEmbed,
    formCategory: formObject.category,
    formId: formObject.id,
    organizationCountry: formCountry,
  };

  return (
    <Stack direction="column" spacing={2}>
      {/* Main checkbox to enable donation in honor of someone */}
      <Checkbox
        control={form.control}
        checked={form.watch("inHonourOf")}
        label={t("donationForm", "donationInHonourOf")}
        size="small"
        name="inHonourOf"
        onChange={toggleIsInHonour}
        data-test="donation-form-in-honour-of"
      />

      {isInHonour && (
        <>
          {/* Field for the honoree's name */}
          <TextField
            control={form.control}
            name="inHonourName"
            label={t("donationForm", "inHonourNameLabel")}
            errorMessage={errors.inHonourName?.message}
            rules={[
              {
                required: {
                  value: isInHonour,
                  message: t("donationForm", "inHonourNameRequired"),
                },
              },
            ]}
            onFocus={logAmplitudeEventCallback(AmplitudeEvents.DonationFocusGeneralInput, {
              label: "inHonourName",
              ...amplitudePayload,
            })}
          />

          {/* Checkbox to enable notification functionality */}
          <Checkbox
            control={form.control}
            checked={form.watch("notifySomeone")}
            size="small"
            name="notifySomeone"
            label={t("donationForm", "notifySomeoneForTheDonation")}
            onChange={toggleNotifySomeone}
          />

          {notifySomeone && (
            <Stack spacing={2}>
              {/* Email field for notification recipient */}
              <TextField
                control={form.control}
                name="inHonourNotifyEmail"
                label={t("donationForm", "inHonourNotifyEmailLabel")}
                errorMessage={errors["inHonourNotifyEmail"]?.message}
                rules={[
                  {
                    required: {
                      value: notifySomeone,
                      message: t("donationForm", "inHonourNotifyEmailRequired"),
                    },
                  },
                  isEmail(t("common", "emailFormat"), t("dashboard", "common.emailFormatWrongChar")),
                ]}
                onFocus={logAmplitudeEventCallback(AmplitudeEvents.DonationFocusGeneralInput, {
                  label: "inHonourNotifyEmail",
                  ...amplitudePayload,
                })}
              />

              {/* Message field for custom notification message */}
              <TextField
                multiline
                rows={4}
                control={form.control}
                name="inHonourMessage"
                label={t("donationForm", "inHonourMessageLabel")}
                errorMessage={errors["inHonourMessage"]?.message}
                rules={[
                  {
                    required: {
                      value: notifySomeone,
                      message: t("donationForm", "inHonourMessageRequired"),
                    },
                  },
                ]}
                onFocus={logAmplitudeEventCallback(AmplitudeEvents.DonationFocusGeneralInput, {
                  label: "inHonourMessage",
                  ...amplitudePayload,
                })}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
