import { FC } from "react";

import { FormType } from "@simplyk/common";

import { useFrontendAuctionContext } from "../../../../contexts/FrontendAuctionContext";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useIsGhostPreview } from "../../../LiveFormEditor/LivePreview/useIsGhostPreview";
import { useFormV2Context } from "../../context/FormV2Context";
import { QuestionField } from "../../sharedComponents/QuestionField/QuestionField";

import { FormV2Step2SectionWrapper } from "./FormV2Step2SectionWrapper";

export const FormV2BuyerQuestionsSection: FC = () => {
  const { t } = useTranslate();
  const isGhostPreview = useIsGhostPreview("TicketingV2BuyerQuestionsSection");
  const { questions, type, formObject, isAuction } = useFormV2Context();
  const { hasAlreadyBid } = useFrontendAuctionContext();

  if (isAuction && hasAlreadyBid) {
    return null;
  }

  const buyerQuestions = questions.filter((question) => question.hasRate === false);
  if (buyerQuestions.length === 0) {
    return null;
  }

  return (
    <FormV2Step2SectionWrapper title={t("ticketing", "questions")} isGhostPreview={isGhostPreview}>
      {buyerQuestions.map((question, index) => {
        return type === FormType.Ticketing ? (
          <QuestionField
            key={question?.id}
            name={`customAnswersOfOrder.${index}`}
            question={question}
            id={`buyer-question-${index}`}
            organizationCountry={formObject.organization?.country}
            sx={{ marginTop: 1.5 }}
          />
        ) : (
          <QuestionField
            key={question?.id}
            name={`answers.${index}`}
            question={question}
            id={`buyer-question-${index}`}
            organizationCountry={formObject.organization?.country}
            sx={{ marginTop: 1.5 }}
          />
        );
      })}
    </FormV2Step2SectionWrapper>
  );
};
