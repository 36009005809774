import { FC, useContext } from "react";

import { UseFormSetValue } from "react-hook-form";

import { useFreePaymentSubmit } from "../../../../components/FreePaymentProcessor/useFreePaymentSubmit";
import PaymentErrorAlert from "../../../../components/PaymentErrorAlert/PaymentErrorAlert";
import { FrontendTicketingContext } from "../../../../contexts/FrontendTicketingContext";
import { FormType } from "../../../../gql/gql-types";
import { useTranslate } from "../../../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../../../types/donationForm";
import { usePreviewContext } from "../../../LiveFormEditor/LivePreview/context/PreviewContext";
import { useFormV2Context } from "../../context/FormV2Context";
import { useEnterKeyListener } from "../../hooks/useEnterKeyListener";
import { ButtonWithHeartAnimation } from "../../sharedComponents/ButtonWithHeartAnimation/ButtonWithHeartAnimation";

import { TicketingPaymentInput } from "@/types/ticketing";

export const SubmitFreeTicketingButton: FC = () => {
  const { t } = useTranslate();
  const { formObject: ticketing, form, currentStep } = useFormV2Context(FormType.Ticketing);
  const { handleSubmit, formState, setValue } = form;
  const { errors } = formState;
  const { validDiscount } = useContext(FrontendTicketingContext);
  const { isPreview } = usePreviewContext();

  const { onSubmit, paymentError, paymentInProgress } = useFreePaymentSubmit({
    handleSubmit,
    formObject: ticketing,
    validDiscount,
    setValue: setValue as UseFormSetValue<TicketingPaymentInput | DonationFormPaymentInput>,
    errors,
    formId: ticketing.id,
    label: "",
    stripeProductId: undefined,
    formType: FormType.Ticketing,
  });

  const submit = async () => {
    onSubmit();
  };

  // Both Step 1 and Step 2 are present in the DOM for better transition, so we need to disable the "Enter" key listener on Step 1
  useEnterKeyListener(submit, currentStep === "payment");

  return (
    <>
      <PaymentErrorAlert paymentError={paymentError} />
      <ButtonWithHeartAnimation
        onClick={submit}
        disabled={isPreview || paymentInProgress}
        label={t("common", "confirm")}
        data-test="submit-free-ticketing-button"
        showAnimation={formState.isValid}
        shouldLoop={true}
      />
    </>
  );
};
