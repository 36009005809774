import { useEffect } from "react";

export const useEnterKeyListener = (onEnter: () => void, isEnabled: boolean = true) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onEnter();
      }
    };

    if (isEnabled) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (isEnabled) {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [onEnter, isEnabled]);
};
