import { useCallback, useEffect, useState } from "react";

import { useElements } from "@stripe/react-stripe-js";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";

export const useCardPaymentFormValid = () => {
  const elements = useElements();
  const [isCardPaymentFormValid, setIsCardPaymentFormValid] = useState(false);

  const handleChange = useCallback(async (element: StripePaymentElementChangeEvent) => {
    if (element.complete) {
      setIsCardPaymentFormValid(true);
    } else {
      setIsCardPaymentFormValid(false);
    }
  }, []);

  const element = elements?.getElement("payment");
  useEffect(() => {
    if (!element) {
      return;
    }
    element?.on("change", handleChange);

    return () => {
      element?.off("change", handleChange);
    };
  }, [element, handleChange]);

  return { isCardPaymentFormValid };
};
