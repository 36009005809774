import { FC, PropsWithChildren } from "react";

import { Grid } from "@mui/material";

import { Typography } from "../../../../components/design-system/Typography";
import { GHOST_PREVIEW_OPACITY } from "../../../LiveFormEditor/LivePreview/ghostPreview.constant";

type Props = PropsWithChildren<{
  title: string;
  isGhostPreview?: boolean;
}>;

export const FormV2Step2SectionWrapper: FC<Props> = ({ title, children, isGhostPreview }) => {
  return (
    <Grid item sx={{ ...(isGhostPreview && { opacity: GHOST_PREVIEW_OPACITY }) }}>
      <Typography
        variant="h6"
        sx={{
          marginBottom: 2,
        }}
        vibe="text-form-intense"
      >
        {title}
      </Typography>

      {children}
    </Grid>
  );
};
