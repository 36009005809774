import { useContext } from "react";

import { SxProps } from "@mui/material";
import { FormType, DonationFormCategory } from "@simplyk/common";

import { FrontendFormContext } from "../../../../contexts/FrontendFormContext";
import { useFormV2Context } from "../../context/FormV2Context";

import { Checkbox } from "@/design-system/Checkbox/Checkbox";
import { useTranslate } from "@/hooks/useTranslate";

type PublicDonationCheckboxProps = {
  sx?: SxProps;
};

export const PublicDonationCheckbox = ({ sx }: PublicDonationCheckboxProps) => {
  const { form } = useFormV2Context(FormType.DonationForm);
  const { t } = useTranslate();

  return (
    <Checkbox
      size="small"
      name="isPublicDonation"
      control={form.control}
      label={t("donationForm", "isPublicDonation")}
      defaultChecked={true}
      sx={sx}
    />
  );
};

export const usePublicDonationCheckboxRenderPosition = () => {
  const { formCategory, type } = useFormV2Context();
  const { displayExpressCheckout } = useContext(FrontendFormContext);

  if (type !== FormType.DonationForm) {
    return null;
  }

  // We only display the public donation checkbox for donation campaigns and peer to peer campaigns
  if (![DonationFormCategory.DonationCampaign, DonationFormCategory.PeerToPeerCampaign].includes(formCategory)) {
    return null;
  }

  return displayExpressCheckout ? "summary-section" : "your-information-section";
};
