import { FC, useContext, useMemo } from "react";

import { Box } from "@mui/material";
import { FormType } from "@simplyk/common";
import { UseFormReturn } from "react-hook-form";

import { PaymentElement } from "../../../../components/PaymentElement/PaymentElement";
import { GiveAsOrganism } from "../../../../components/PaymentInformation/GiveAsOrganism";
import { useFrontendAuctionContext } from "../../../../contexts/FrontendAuctionContext";
import { FrontendFormContext } from "../../../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../../../contexts/FrontendTicketingContext";
import { useTranslate } from "../../../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../../../types/donationForm";
import { useIsGhostPreview } from "../../../LiveFormEditor/LivePreview/useIsGhostPreview";
import { useFormV2Context } from "../../context/FormV2Context";
import { useAnimationShapesOnTipChange } from "../../sharedComponents/BackgroundShapes/useAnimationShapesOnTipChange";

import { FormV2Step2SectionWrapper } from "./FormV2Step2SectionWrapper";

import { TicketingPaymentInput } from "@/types/ticketing";

export const FormV2PaymentSection: FC = () => {
  const { t } = useTranslate();
  const { displayExpressCheckout, isEmbed, displayedFormAmount } = useContext(FrontendFormContext);
  const { validDiscount } = useContext(FrontendTicketingContext);
  const { form, formObject, type, formCategory, isAuction } = useFormV2Context();
  const { hasAlreadyBid } = useFrontendAuctionContext();
  const { formState } = form;

  const isFreeTicket = displayedFormAmount === 0;

  const amplitudePayload = useMemo(
    () => ({
      isEmbed,
      formCategory,
      formId: formObject.id,
      organizationCountry: formObject.organization?.country,
    }),
    [isEmbed, formCategory, formObject.id, formObject.organization?.country]
  );

  useAnimationShapesOnTipChange();

  const isPaymentSectionGhostPreview = useIsGhostPreview("FormV2PaymentSection");

  if ((type === FormType.Ticketing && isFreeTicket) || (isAuction && hasAlreadyBid)) {
    return null;
  }

  return (
    <FormV2Step2SectionWrapper title={t("common", "paymentMethod")} isGhostPreview={isPaymentSectionGhostPreview}>
      {displayExpressCheckout && (
        <Box marginBottom={2}>
          <GiveAsOrganism
            {...(form as UseFormReturn<TicketingPaymentInput | DonationFormPaymentInput>)}
            errors={form.formState.errors}
            amplitudePayload={amplitudePayload}
            formType={type}
          />
        </Box>
      )}

      <PaymentElement
        {...(form as UseFormReturn<TicketingPaymentInput | DonationFormPaymentInput>)}
        formObject={formObject}
        errors={formState.errors}
        shouldDisplaySubmitButton={false}
        validDiscount={validDiscount}
      />
    </FormV2Step2SectionWrapper>
  );
};
