import { useCallback, useContext, useMemo, useState } from "react";

import { FormType, convertCountryCodeToCountry, Emptyable, CountryCode, PaymentMethod } from "@simplyk/common";
import { useElements } from "@stripe/react-stripe-js";
import { inferRouterOutputs } from "@trpc/server";
import Router, { useRouter } from "next/router";
import { FieldErrors, UseFormHandleSubmit, UseFormSetValue } from "react-hook-form";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { SessionContext } from "../../contexts/SessionContext";
import { CommandSource, DiscountObject } from "../../gql/gql-types";
import { isDonationForm } from "../../helpers/form";
import { getErrorMessages } from "../../helpers/reactHookForm";
import { getThankYouPageUrl } from "../../helpers/thankYouPage";
import { runWithMinimumDuration } from "../../helpers/time";
import { AppRouterType } from "../../helpers/trpc";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useCreateRegistrationCampaigns } from "../../hooks/useCreateRegistrationCampaigns";
import { useDurationOnPage } from "../../hooks/useDurationOnPage";
import { useIsEmbeddedModal } from "../../hooks/useEmbeddedModal";
import { useTranslate } from "../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../types/donationForm";
import { getAnimationNumber, TIME_BETWEEN_STEPS } from "../Animations/ThankYouLoader/ThankYouLoader";
import { usePaymentErrorContext } from "../PaymentElement/PaymentErrorContext";
import { SubmitCommandFormInput } from "../PaymentProcessor/helper";
import { useSubmitCommand } from "../PaymentProcessor/hooks/useSubmitCommand";
import { CustomRedirect, Event, isPaymentFailed, PaymentSucceeded } from "../PaymentProcessor/type";

import { AmplitudeEvents } from "@/constants/amplitude";
import { captureSentryPaymentError } from "@/helpers/sentry";
import { TicketingPaymentInput } from "@/types/ticketing";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

export const formObjectIsTicketing = (
  formObject: TicketingOutput | DonationFormOutput,
  formType: FormType
): formObject is TicketingOutput => {
  return formType === FormType.Ticketing;
};

type SubmitCommandOutput = inferRouterOutputs<AppRouterType>["form_submitCommand"];

export interface FreePaymentProcessorProps {
  handleSubmit: UseFormHandleSubmit<TicketingPaymentInput | DonationFormPaymentInput>;
  setValue: UseFormSetValue<TicketingPaymentInput | DonationFormPaymentInput>;
  formId: string;
  label: string;
  stripeProductId: Emptyable<string>;
  validDiscount: DiscountObject | null;
  formObject: TicketingOutput | DonationFormOutput;
  formType: FormType;
  event?: Event;
  errors: FieldErrors;
  customRedirect?: CustomRedirect;
}

export const useFreePaymentSubmit = (props: FreePaymentProcessorProps) => {
  const { handleSubmit } = props;
  const elements = useElements();

  const { ticketing, hasADate } = useContext(FrontendTicketingContext);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const { paymentError, setPaymentError } = usePaymentErrorContext();

  const { logAmplitudeEvent } = useAmplitude();
  const router = useRouter();
  const isEmbeddedModal = useIsEmbeddedModal(router.query);
  const { t } = useTranslate();
  const { handleSubmitCommand } = useSubmitCommand(props);
  const { getDurationTimeOnPage } = useDurationOnPage();

  const {
    commandId,
    organization,
    postTransactionUrl,
    tip,
    selectedPaymentMethod,
    isEmbed,
    category,
    formData,
    selectedTip,
    displayedFormAmount,
    setIsSubmitting,
    generateETicket,
    formAmplitudeContext,
    themeColor,
  } = useContext(FrontendFormContext);
  const { locale, isoLocale } = useLocaleContext();
  const { sessionId } = useContext(SessionContext);

  const { shouldCreateCampaigns, createCampaigns } = useCreateRegistrationCampaigns();

  const handlePaymentFailed = useCallback(
    async ({ errors, params }: { errors?: string[]; params?: Record<string, unknown> }) => {
      captureSentryPaymentError({
        message: "payment failed",
        paymentMethodType: PaymentMethod.Free,
        params: { errors, ...params },
      });
      setPaymentInProgress(false);
      setIsSubmitting(false);

      const displayedErrors = errors?.[0] || t("common", "unknownError", { code: 100 });
      setPaymentError(displayedErrors as string);
    },
    [setIsSubmitting, setPaymentError, t]
  );

  const amplitudePayload = useMemo(
    () => ({
      formType: category,
      countCustomQuestions: props.formObject.questions?.length,
      displayAddressQuestion: props.formObject.displayAddressQuestion,
      encourageMonthlyDonation: isDonationForm(formData) && Boolean(formData.encourageMonthlyDonations),
      hasALinkedTicketing: isDonationForm(formData) && Boolean(formData.redirectUrl),
      hasAdditionalDonation: Boolean(ticketing.extraDonation),
      hasBanner: Boolean(props.formObject.bannerUrl),
      hasBannerVideo: Boolean(props.formObject.bannerVideoUrl),
      hasCheckOption: props.formObject.allowCheque,
      hasInHonourOption: isDonationForm(formData) && Boolean(formData.allowInHonour),
      hasLogo: Boolean(props.formObject.logoUrl),
      hasNoSuggestedAmount: formAmplitudeContext.hasNoSuggestedAmount || null,
      hasReceipt: isDonationForm(formData) && formData.hasReceipt,
      hasReceiptMessage: formAmplitudeContext.hasReceiptMessage || null,
      hasTarget: Boolean(ticketing.thermometerTarget),
      isEmbed,
      isPrimaryCampaign: isDonationForm(formData) && formData.isPrimaryCampaign,
      isPrimaryTeam: isDonationForm(formData) && formData.isPrimaryTeam,
      isoLocale,
      locale,
      organizationCountry: organization?.country,
      tipAmount: tip,
      tipChoice: selectedTip.percentage,
      tipPercentage: displayedFormAmount > 0 ? ((tip / displayedFormAmount) * 100).toFixed(2) : "N/A",
      formId: props.formObject.id,
      amount: displayedFormAmount,
      paymentMethod: selectedPaymentMethod,
      isExpressCheckout: false,
    }),
    [
      category,
      displayedFormAmount,
      formAmplitudeContext.hasNoSuggestedAmount,
      formAmplitudeContext.hasReceiptMessage,
      formData,
      isEmbed,
      isoLocale,
      locale,
      organization?.country,
      props.formObject.allowCheque,
      props.formObject.bannerUrl,
      props.formObject.bannerVideoUrl,
      props.formObject.displayAddressQuestion,
      props.formObject.id,
      props.formObject.logoUrl,
      props.formObject.questions?.length,
      selectedPaymentMethod,
      selectedTip.percentage,
      ticketing.extraDonation,
      ticketing.thermometerTarget,
      tip,
    ]
  );

  const handleFormSubmit = handleSubmit(async (paymentInput: TicketingPaymentInput | DonationFormPaymentInput) => {
    setPaymentInProgress(true);
    setIsSubmitting(true);
    const minimumDuration = TIME_BETWEEN_STEPS * 1000 * getAnimationNumber({ isFree: true, hasADate });

    const url = await runWithMinimumDuration<Emptyable<string>>(async () => {
      logAmplitudeEvent(AmplitudeEvents.ConfirmFreePayment);

      const session = {
        sessionId,
        durationOnPage: getDurationTimeOnPage(),
      };

      const addressElement = elements?.getElement?.("address");
      const addressValue = await addressElement?.getValue?.();
      if (addressValue) {
        const { city, country, line1, line2, postal_code, state } = addressValue.value.address;
        paymentInput.address = line2 ? `${line1} ${line2}` : line1;
        paymentInput.city = city;
        paymentInput.postalCode = postal_code;
        paymentInput.region = state;
        paymentInput.country = convertCountryCodeToCountry(country as CountryCode);
        paymentInput.firstName = addressValue.value.firstName;
        paymentInput.lastName = addressValue.value.lastName;
      }

      const submitCommandInput: SubmitCommandFormInput = {
        paymentInput,
        paymentMethod: PaymentMethod.Free,
        source: CommandSource.FormSubmission,
        formObject: props.formObject,
        user: null,
        formType: props.formType,
        validDiscount: props.validDiscount,
        stripePaymentMethodId: null,
        session,
        routerQuery: router.query,
        commandId,
        locale,
        tip: 0,
        tipPercentage: 0,
      };

      try {
        const result = await handleSubmitCommand(submitCommandInput);
        if (isPaymentFailed(result)) {
          await handlePaymentFailed({ errors: [result.error.message] });
          logAmplitudeEvent(AmplitudeEvents.DonorFormSubmitted, {
            ...amplitudePayload,
            errors: result.error,
          });
          return { callbackResult: null, shouldAwaitMinimumDuration: false };
        } else {
          const url = await handlePaymentFinalized(result, paymentInput);
          logAmplitudeEvent(AmplitudeEvents.DonorFormSubmitted, amplitudePayload);
          return { callbackResult: url, shouldAwaitMinimumDuration: true };
        }
      } catch (errors) {
        await handlePaymentFailed({ errors: errors as string[] });
        logAmplitudeEvent(AmplitudeEvents.DonorFormSubmitted, {
          ...amplitudePayload,
          errors,
        });
      }
      return { callbackResult: null, shouldAwaitMinimumDuration: false };
    }, minimumDuration).finally(() => {
      setPaymentInProgress(false);
      setIsSubmitting(false);
    });
    if (url) {
      await Router.push(url);
    }
  });

  const handlePaymentFinalized = useCallback(
    async (result: PaymentSucceeded, paymentInput: TicketingPaymentInput | DonationFormPaymentInput) => {
      const command = result.data?.command;
      const formType = props.formType;

      if (shouldCreateCampaigns(paymentInput, formType)) {
        const result = await createCampaigns({
          firstName: command?.firstName,
          lastName: command?.lastName,
          email: command?.email,
          paymentInput,
        });
        if (result) {
          if (result.returnUrl) {
            return result.returnUrl;
          }
          return null;
        }
      }

      if (result.onSuccess) {
        await result.onSuccess();
        return null;
      }

      const event = props.event;

      return getThankYouPageUrl({
        command: command as NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"],
        formType,
        organization,
        postTransactionUrl,
        isEmbeddedModal,
        generateETicket,
        event,
        formData,
        isoLocale,
        category,
        isAuction: false,
        themeColor,
      });
    },
    [
      category,
      createCampaigns,
      formData,
      generateETicket,
      isEmbeddedModal,
      isoLocale,
      organization,
      postTransactionUrl,
      props.event,
      props.formType,
      shouldCreateCampaigns,
      themeColor,
    ]
  );

  const onSubmit = useCallback(
    async (event?: React.MouseEvent) => {
      event && event.preventDefault();
      // Form is submitted, but can be invalid
      await handleFormSubmit();

      if (Object.keys(props.errors).length > 0) {
        logAmplitudeEvent(AmplitudeEvents.DonorFormSubmitted, {
          ...amplitudePayload,
          errors: getErrorMessages({
            keys: Object.keys(props.errors),
            errors: props.errors,
          }),
        });
      }
    },
    [amplitudePayload, handleFormSubmit, logAmplitudeEvent, props.errors]
  );

  return { onSubmit, paymentError, paymentInProgress };
};
