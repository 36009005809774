import { useContext, useEffect } from "react";

import { Grid, MenuItem } from "@mui/material";
import { OrganizationCountry } from "@simplyk/common";
import countryRegionData from "country-region-data";

import { GiveAsOrganism } from "../../../../components/PaymentInformation/GiveAsOrganism";
import { isRequired } from "../../../../helpers/validators";
import { useDefaultCountryRegion } from "../../../../hooks/useDefaultCountryRegion";
import { useTranslate } from "../../../../hooks/useTranslate";
import { usePreviewContext } from "../../../LiveFormEditor/LivePreview/context/PreviewContext";
import { useFormV2Context } from "../../context/FormV2Context";

import { FormV2Step2SectionWrapper } from "./FormV2Step2SectionWrapper";
import { PublicDonationCheckbox, usePublicDonationCheckboxRenderPosition } from "./PublicDonationCheckBox";

import { EmailTextField } from "@/design-system/EmailTextField/EmailTextField";
import { Select } from "@/design-system/Select/Select";
import { TextField } from "@/design-system/TextField/TextField";
import { useCountryRegion } from "@/hooks/useCountryRegion";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { FrontendFormContext } from "@/src/contexts/FrontendFormContext";

export const FormV2BuyerInformationSection = () => {
  const { t } = useTranslate();
  const { isEmbed, organization, displayAddressQuestion, displayExpressCheckout } = useContext(FrontendFormContext);
  const { form, type } = useFormV2Context("common");

  const { control, setValue, watch, formState } = form;
  const { errors } = formState;
  const { isPreview } = usePreviewContext();

  const countryValue = watch("country");
  const { currentCountryRegions } = useCountryRegion(countryValue);
  const { isSmallScreen } = useMediaQuery();
  const { defaultCountry, defaultRegion } = useDefaultCountryRegion(organization);
  const publicDonationCheckboxPosition = usePublicDonationCheckboxRenderPosition();
  useEffect(() => {
    setValue("country", defaultCountry);
    setValue("region", defaultRegion);
  }, [defaultCountry, defaultRegion, setValue]);

  return (
    <FormV2Step2SectionWrapper title={t("ticketing", "yourInformation")}>
      <Grid
        item
        container
        xs={12}
        sx={{
          paddingTop: 0,
        }}
      >
        <Grid item xs={12} sx={{ marginBottom: (theme) => theme.spacing(1) }}>
          <EmailTextField
            variant="error"
            control={control}
            name="email"
            errorMessage={errors["email"]?.message}
            type="email"
            label={t("ticketing", "email")}
            data-test="form-email"
            disableInjectingGlobalStyles={isPreview}
            rules={[isRequired(t("ticketing", "emailRequired"))]}
            checkMisspelledEmail
          />
        </Grid>

        <Grid container item spacing={1} sx={{ marginBottom: 1 }}>
          <Grid item xs={6}>
            <TextField
              control={control}
              name="firstName"
              errorMessage={errors["firstName"]?.message}
              label={t("ticketing", "firstName")}
              data-test="form-firstname"
              disableInjectingGlobalStyles={isPreview}
              rules={[isRequired(t("ticketing", "firstNameRequired"))]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              control={control}
              name="lastName"
              errorMessage={errors["lastName"]?.message}
              label={t("ticketing", "lastName")}
              data-test="form-lastname"
              disableInjectingGlobalStyles={isPreview}
              rules={[isRequired(t("ticketing", "lastNameRequired"))]}
            />
          </Grid>
        </Grid>

        {publicDonationCheckboxPosition === "your-information-section" && (
          <Grid item spacing={1} sx={{ marginBottom: 1 }}>
            <PublicDonationCheckbox />
          </Grid>
        )}

        {displayAddressQuestion && (
          <Grid container item spacing={1} sx={{ marginBottom: 1 }}>
            <Grid item xs={12}>
              <TextField
                control={control}
                name="address"
                errorMessage={errors["address"]?.message}
                label={t("ticketing", "address")}
                data-test="form-address"
                disableInjectingGlobalStyles={isPreview}
                rules={[
                  {
                    required: {
                      value: true,
                      message: t("ticketing", "addressRequired"),
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                control={control}
                name="city"
                errorMessage={errors["city"]?.message}
                label={t("ticketing", "city")}
                data-test="form-city"
                disableInjectingGlobalStyles={isPreview}
                rules={[isRequired(t("ticketing", "cityRequired"))]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                control={control}
                name="postalCode"
                errorMessage={errors["postalCode"]?.message}
                label={
                  organization.country === OrganizationCountry.Canada
                    ? t("ticketing", "postalCode")
                    : t("ticketing", "zipCode")
                }
                data-test="form-postalcode"
                disableInjectingGlobalStyles={isPreview}
                rules={[
                  isRequired(
                    t(
                      "ticketing",
                      organization.country === OrganizationCountry.Canada ? "postalCodeRequired" : "zipCodeRequired"
                    )
                  ),
                ]}
              />
            </Grid>
          </Grid>
        )}

        <Grid container item spacing={1} sx={{ marginBottom: 1 }}>
          <Grid item xs={12} md={6} sx={{ marginBottom: isSmallScreen ? 1 : 0 }}>
            <Select
              disableAutoFocus={isEmbed}
              control={control}
              name="country"
              errorMessage={errors["country"]?.message}
              label={t("ticketing", "country")}
              data-test="form-country"
              defaultValue={defaultCountry}
              rules={[{ required: "common:fieldIsRequired" }]}
              disableInjectingGlobalStyles={isPreview}
            >
              {countryRegionData.map((country) => (
                <MenuItem key={country.countryName} value={country.countryName} data-test="form-country-option">
                  {`${country.countryName} (${country.countryShortCode})`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              disableAutoFocus={isEmbed}
              control={control}
              name="region"
              errorMessage={errors["region"]?.message}
              label={countryValue === "Canada" ? t("ticketing", "region") : t("ticketing", "state")}
              defaultValue={defaultRegion}
              data-test="form-region"
              rules={[{ required: "common:fieldIsRequired" }]}
              disableInjectingGlobalStyles={isPreview}
            >
              {currentCountryRegions.map((region, index) => (
                <MenuItem key={index} value={region.name} data-test="form-region-option">
                  {`${region.name} (${region.shortCode})`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container item spacing={1} sx={{ marginBottom: 1 }} xs={12}>
          {!displayExpressCheckout && (
            <Grid container item spacing={1} sx={{ marginBottom: 1, marginTop: 0 }}>
              <Grid item xs={12}>
                <GiveAsOrganism {...form} errors={errors} amplitudePayload={{}} formType={type} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormV2Step2SectionWrapper>
  );
};
