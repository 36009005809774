/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-all-duplicated-branches */
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box, Theme } from "@mui/material";
import { styled } from "@mui/system";
import { FormType } from "@simplyk/common";
import dynamic from "next/dynamic";

import { Translations } from "../../../../public/@types/translations";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useTranslate } from "../../../hooks/useTranslate";
import { Typography } from "../../design-system/Typography";
import { ZeffyLoader } from "../../design-system/ZeffyLoader/ZeffyLoader";

const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

export const TIME_BETWEEN_STEPS = 3;
const TIME_FADE = 0.5;

export const getAnimationNumber = ({
  isFree,
  hasADate,
}: { isFree: true; hasADate: boolean } | { isFree: false; hasADate?: boolean }) => {
  return isFree && !hasADate ? 1 : isFree ? 2 : 3;
};

const FadeOutBox = memo(styled("div")`
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.fade-out {
    animation: fadeOut 0.3s ease-in-out forwards;
  }
`);

const FadeBox = memo(styled("div")`
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-40px);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(40px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.fade-out {
    animation: fadeOut ${TIME_FADE}s ease-in-out forwards;
  }

  &.fade-in {
    animation: fadeIn ${TIME_FADE}s ease-in-out forwards;
  }
`);

const Dots = memo(styled("div")`
  display: inline-block;
  font-size: 24px;
  font-weight: bold;

  @keyframes dots {
    0%,
    20% {
      content: "\u00A0"; /* Non-breaking space to maintain the height */
    }
    40% {
      content: ".";
    }
    60% {
      content: "..";
    }
    80%,
    100% {
      content: "...";
    }
  }

  &::after {
    content: "\u00A0"; /* Non-breaking space to maintain the height */
    animation: dots 1.5s steps(4, end) infinite;
  }
`);

interface ThankYouLoaderProps {
  organizationName: string;
  formType: FormType;
  generateTaxReceipt: boolean;
  generateTicket: boolean;
  isFree: boolean;
  hasADate: boolean;
}

enum STEPS {
  DELIVERY_MONEY = "DELIVERY_MONEY",
  PRINTER = "PRINTER",
  INBOX = "INBOX",
}

const textSx = (theme: Theme) => ({
  color: theme.palette.text.neutral.moderate,
  textAlign: "center",
  marginTop: "-50px",
});

export const ThankYouLoader: FC<ThankYouLoaderProps> = ({
  organizationName,
  formType,
  generateTaxReceipt,
  generateTicket,
  isFree,
  hasADate,
}) => {
  const { t } = useTranslate();
  const { isSmallScreen } = useMediaQuery();
  const [step, setStep] = useState(isFree && !hasADate ? STEPS.INBOX : isFree ? STEPS.PRINTER : STEPS.DELIVERY_MONEY);
  const [fade, setFade] = useState("fade-in");
  const [isLoadingAnimation, setIsLoadingAnimation] = useState(true);
  const [thankYouPageAnimationDataDeliveryMoney, setThankYouPageAnimationDataDeliveryMoney] = useState<unknown | null>(
    null
  );
  const [thankYouPageAnimationDataPrinter, setThankYouPageAnimationDataPrinter] = useState<unknown | null>(null);
  const [thankYouPageAnimationDataEmail, setThankYouPageAnimationDataEmail] = useState<unknown | null>(null);

  const steps = useMemo(
    () =>
      Object.values(STEPS).filter(
        (value) => !(isFree && value === STEPS.DELIVERY_MONEY) && !(isFree && !hasADate && value === STEPS.PRINTER)
      ),
    [hasADate, isFree]
  );

  useEffect(() => {
    let currentIndex = 0;

    const interval = setInterval(() => {
      setFade("fade-out");

      setTimeout(() => {
        currentIndex = (currentIndex + 1) % steps.length;
        setStep(steps[currentIndex]);
        setFade("fade-in");
      }, TIME_FADE * 1000);
    }, TIME_BETWEEN_STEPS * 1000);

    return () => clearInterval(interval);
  }, [steps]);

  const getPrinterMessageKeyAndAnimationPathName: () => {
    animationType: "printer_calendar" | "printer_receipt" | "printer_tickets";
    messageKey: keyof Translations["common"] & string;
  } = useCallback(() => {
    if (formType === FormType.DonationForm) {
      if (generateTaxReceipt) {
        return {
          animationType: "printer_receipt",
          messageKey: "thankYouLoader.printTaxReceipt",
        };
      } else {
        return {
          animationType: "printer_receipt",
          messageKey: "thankYouLoader.printTransaction",
        };
      }
    } else {
      if (generateTicket) {
        return {
          animationType: "printer_tickets",
          messageKey: "thankYouLoader.printTicket",
        };
      }
      if (generateTaxReceipt) {
        return {
          animationType: "printer_receipt",
          messageKey: "thankYouLoader.printTaxReceipt",
        };
      }
      if (hasADate && isFree) {
        return {
          animationType: "printer_calendar",
          messageKey: "thankYouLoader.printCalendar",
        };
      }
      return {
        animationType: "printer_receipt",
        messageKey: "thankYouLoader.printTransaction",
      };
    }
  }, [formType, generateTaxReceipt, generateTicket, hasADate, isFree]);

  useEffect(() => {
    const fetchThankYouPageAnimation = async () => {
      const printerAnimationType = getPrinterMessageKeyAndAnimationPathName().animationType;
      const [animationDataDeliveryMoney, animationDataPrinter, animationDataEmail] = await Promise.all([
        import("./animations/delivery_money.json"),
        printerAnimationType === "printer_receipt"
          ? import("./animations/printer_receipt.json")
          : printerAnimationType === "printer_tickets"
            ? import("./animations/printer_tickets.json")
            : import("./animations/printer_calendar.json"),
        import("./animations/inbox.json"),
      ]);

      setThankYouPageAnimationDataDeliveryMoney(animationDataDeliveryMoney);
      setThankYouPageAnimationDataPrinter(animationDataPrinter);
      setThankYouPageAnimationDataEmail(animationDataEmail);
      setIsLoadingAnimation(false);
    };
    fetchThankYouPageAnimation();
  }, [getPrinterMessageKeyAndAnimationPathName, organizationName]);

  const getAnimationWithText = useCallback(
    (animationData: unknown, text: string) => {
      return (
        <>
          <Lottie
            options={{
              animationData,
              autoplay: true,
              loop: true,
            }}
            isClickToPauseDisabled={true}
            style={{ width: isSmallScreen ? "100%" : "60%" }}
          />
          <Typography variant="h5" sx={textSx}>
            {text}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Dots />
          </Box>
        </>
      );
    },
    [isSmallScreen]
  );

  const animation = useMemo(() => {
    const printerMessageKey = getPrinterMessageKeyAndAnimationPathName().messageKey;
    switch (step) {
      case STEPS.DELIVERY_MONEY:
        return getAnimationWithText(
          thankYouPageAnimationDataDeliveryMoney,
          t(
            "common",
            formType === FormType.DonationForm
              ? "thankYouLoader.deliveryMoney.donation"
              : "thankYouLoader.deliveryMoney.ticketing",
            { orgName: organizationName }
          )
        );
      case STEPS.PRINTER:
        return getAnimationWithText(thankYouPageAnimationDataPrinter, t("common", printerMessageKey));
      case STEPS.INBOX:
        return getAnimationWithText(
          thankYouPageAnimationDataEmail,
          t("common", steps.length === 1 ? "thankYouLoader.email.only-one-animation" : "thankYouLoader.email")
        );
    }
  }, [
    formType,
    getAnimationWithText,
    getPrinterMessageKeyAndAnimationPathName,
    organizationName,
    step,
    steps.length,
    t,
    thankYouPageAnimationDataDeliveryMoney,
    thankYouPageAnimationDataEmail,
    thankYouPageAnimationDataPrinter,
  ]);

  return (
    <FadeOutBox sx={{ margin: "auto" }} data-test="thank-you-loader">
      {isLoadingAnimation ? <ZeffyLoader /> : <FadeBox className={fade}>{animation}</FadeBox>}
    </FadeOutBox>
  );
};
