/* eslint-disable sonarjs/no-nested-switch */
import { FC, useContext } from "react";

import { FormType, PaymentMethod, TicketingFormCategory } from "@simplyk/common";
import { UseFormReturn } from "react-hook-form";

import { usePaymentErrorContext } from "../../../../components/PaymentElement/PaymentErrorContext";
import { usePaymentSubmit } from "../../../../components/PaymentElement/usePaymentSubmit";
import { FrontendFormContext } from "../../../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../../../contexts/FrontendTicketingContext";
import { useTranslate } from "../../../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../../../types/donationForm";
import { usePreviewContext } from "../../../LiveFormEditor/LivePreview/context/PreviewContext";
import { useFormV2Context } from "../../context/FormV2Context";
import { useEnterKeyListener } from "../../hooks/useEnterKeyListener";
import { ButtonWithHeartAnimation } from "../../sharedComponents/ButtonWithHeartAnimation/ButtonWithHeartAnimation";
import { useCardPaymentFormValid } from "../hooks/useCardPaymentFormValid";

import { Lock } from "@/icons/outlined";
import { TicketingPaymentInput } from "@/types/ticketing";

export const SubmitCommandButton: FC = () => {
  const { t } = useTranslate();
  const { formObject, form, currentStep } = useFormV2Context();

  const { validDiscount } = useContext(FrontendTicketingContext);
  const { selectedPaymentMethod, setSelectedPaymentMethod, isExpressCheckoutLoading, showProceedButton } =
    useContext(FrontendFormContext);
  const { isPreview } = usePreviewContext();

  const { setPaymentError } = usePaymentErrorContext();

  const { handleSubmit, formState, setValue, trigger, control } = form as UseFormReturn<
    TicketingPaymentInput | DonationFormPaymentInput
  >; // Cast UseFormReturn<TicketingPaymentInput> | UseFormReturn<DonationFormPaymentInput> to UseFormReturn<TicketingPaymentInput> | UseFormReturn<DonationFormPaymentInput>
  const { errors } = formState;
  const { handleFormSubmit } = usePaymentSubmit({
    handleSubmit,
    formObject,
    validDiscount,
    setValue,
    trigger,
    errors,
    control,
  });

  const submit = async () => {
    if (!selectedPaymentMethod) {
      setPaymentError(t("common", "pleaseSelectPaymentMethod"));
      setSelectedPaymentMethod(PaymentMethod.Card);
    }
    handleFormSubmit()();
  };

  // Both Step 1 and Step 2 are present in the DOM for better transition, so we need to disable the "Enter" key listener on Step 1
  useEnterKeyListener(submit, currentStep === "payment");

  const { isCardPaymentFormValid } = useCardPaymentFormValid();

  const shouldTriggerHeartsAnimation = Boolean(
    formState.isValid && (selectedPaymentMethod === PaymentMethod.Card ? isCardPaymentFormValid : true)
  );

  const label = useSubmitLabel();

  const isPaymentDisabled = formObject?.organization?.isPaymentDisabled || false;

  return (
    <ButtonWithHeartAnimation
      onClick={submit}
      disabled={isPreview || isExpressCheckoutLoading || isPaymentDisabled}
      label={label}
      showAnimation={shouldTriggerHeartsAnimation}
      endIcon={showProceedButton ? <Lock /> : undefined}
      shouldLoop={true}
      data-test="form-v2-submit-button"
    />
  );
};

const useSubmitLabel = () => {
  const { showProceedButton } = useContext(FrontendFormContext);
  const { t } = useTranslate();
  const { type, formCategory, form } = useFormV2Context();

  switch (type) {
    case FormType.DonationForm:
      return t("common", "Donate");

    case FormType.Ticketing: {
      if (showProceedButton) {
        return t("common", "paymentMethod.button.proceedToPayment");
      }

      if (formCategory === TicketingFormCategory.Auction) {
        return t("common", "paymentMethod.button.placeBid");
      }

      const totalTickets =
        form.getValues("ticketsPurchased")?.reduce((total, item) => total + (item?.purchasedNumber || 0), 0) || 0;
      if (totalTickets === 0) {
        return t("ticketing", "donate");
      }

      switch (formCategory) {
        case TicketingFormCategory.Event:
        case TicketingFormCategory.Lottery:
          return t("ticketing", "buyTickets");
        case TicketingFormCategory.MembershipV2:
        case TicketingFormCategory.Membership:
        case TicketingFormCategory.Shop:
          return t("ticketing", "buyItems");
        default:
          return t("common", "buy");
      }
    }
  }
};
