import { FC, useContext, useState } from "react";

import { FormType, PaymentMethod } from "@simplyk/common";
import { inferRouterOutputs } from "@trpc/server";
import { useRouter } from "next/router";

import { usePaymentErrorContext } from "../../../../components/PaymentElement/PaymentErrorContext";
import { useAmplitudePaymentEventLog } from "../../../../components/PaymentElement/useAmplitudePaymentEventLog";
import { getEvent } from "../../../../components/PaymentProcessor/helper";
import { FrontendFormContext } from "../../../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../../../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../../../../contexts/LocaleContext";
import { useUserInputContext } from "../../../../contexts/UserInputContext";
import { serializeProductBids } from "../../../../helpers/command";
import { getThankYouPageUrl } from "../../../../helpers/thankYouPage";
import { useIsEmbeddedModal } from "../../../../hooks/useEmbeddedModal";
import { useTranslate } from "../../../../hooks/useTranslate";
import { usePreviewContext } from "../../../LiveFormEditor/LivePreview/context/PreviewContext";
import { useFormV2Context } from "../../context/FormV2Context";
import { ButtonWithHeartAnimation } from "../../sharedComponents/ButtonWithHeartAnimation/ButtonWithHeartAnimation";
import { useCardPaymentFormValid } from "../hooks/useCardPaymentFormValid";

import { AppRouterType, trpc } from "@/helpers/trpc";
import { Lock } from "@/icons/outlined";

type SubmitCommandOutput = inferRouterOutputs<AppRouterType>["form_submitCommand"];
type Command = NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"];

export const SubmitNewBidsButton: FC = () => {
  const { t } = useTranslate();
  const { formObject: ticketing, form } = useFormV2Context(FormType.Ticketing);
  const { mutateAsync: submitNewBids } = trpc.form_submitNewBids.useMutation();
  const { mutateAsync: logCommandUserInteractionMutation } = trpc.form_logCommandUserInteraction.useMutation();

  const router = useRouter();
  const { isoLocale } = useLocaleContext();
  const isEmbeddedModal = useIsEmbeddedModal(router.query);

  const { ticketingField, selectedOccurrenceWithRates, isAuction } = useContext(FrontendTicketingContext);
  const {
    selectedPaymentMethod,
    isExpressCheckoutLoading,
    showProceedButton,
    postTransactionUrl,
    generateETicket,
    selectedTip,
    shouldSendTipPercentage,
    tip,
    formData,
    category,
    organization,
    themeColor,
  } = useContext(FrontendFormContext);
  const { userInputs } = useUserInputContext();

  const { isPreview } = usePreviewContext();
  const { setPaymentError } = usePaymentErrorContext();

  const [paymentInProgress, setPaymentInProgress] = useState(false);

  const { handleSubmit, formState } = form;
  const { errors } = formState;

  const { logDonorFormSubmitted } = useAmplitudePaymentEventLog({ formObject: ticketing, errors });

  const { isCardPaymentFormValid } = useCardPaymentFormValid();

  const shouldTriggerHeartsAnimation = Boolean(
    formState.isValid && (selectedPaymentMethod === PaymentMethod.Card ? isCardPaymentFormValid : true)
  );

  const handlePaymentFailed = async ({ error }: { error?: { message: string } }) => {
    logDonorFormSubmitted({
      isExpressCheckout: false,
      hasSucceeded: false,
      formErrors: error ? [error.message] : [],
      extra: { re_bid: true },
    });
    setPaymentInProgress(false);
    const displayedErrors = error?.message || t("common", "unknownError", { code: 150 });
    setPaymentError(displayedErrors as string);
  };

  const handlePaymentSucceededFinalized = async (command: Command) => {
    const formType = FormType.Ticketing;
    const event =
      ticketingField &&
      getEvent({ ticketing, ticketingField, selectedOccurrence: selectedOccurrenceWithRates?.occurrence });
    logDonorFormSubmitted({
      isExpressCheckout: false,
      hasSucceeded: true,
      extra: { re_bid: true },
    });

    return getThankYouPageUrl({
      command,
      formType,
      organization,
      postTransactionUrl,
      isEmbeddedModal,
      generateETicket,
      event,
      formData,
      isoLocale,
      category,
      isAuction,
      themeColor,
    });
  };

  const handleFormSubmit = handleSubmit(async (paymentInput) => {
    setPaymentInProgress(true);

    const productBids = serializeProductBids(paymentInput.ticketsPurchased, paymentInput.customAnswersOfParticipants);
    const tipPercentage = shouldSendTipPercentage ? selectedTip.percentage || 0 : 0;
    const tipAmount = shouldSendTipPercentage ? 0 : tip;

    if (!productBids?.length) {
      setPaymentInProgress(false);
      return;
    }

    const result = await submitNewBids({
      productBids,
      tipPercentage,
      tipAmount,
      ticketingId: ticketing.id,
    });
    if (result.error) {
      await handlePaymentFailed({ error: result.error });
      return { callbackResult: null, shouldAwaitMinimumDuration: false };
    } else {
      if (userInputs) {
        await logCommandUserInteractionMutation({ commandId: result.object.id, payload: userInputs });
      }
      const url = await handlePaymentSucceededFinalized(result.object);
      if (url) {
        await router.push(url);
      }
    }
    setPaymentInProgress(false);
  });

  const isPaymentDisabled = ticketing?.organization?.isPaymentDisabled || false;

  return (
    <ButtonWithHeartAnimation
      onClick={handleFormSubmit}
      disabled={isPreview || isExpressCheckoutLoading || paymentInProgress || isPaymentDisabled}
      isLoading={paymentInProgress}
      label={t("common", "paymentMethod.button.placeBid")}
      showAnimation={shouldTriggerHeartsAnimation}
      endIcon={showProceedButton ? <Lock /> : undefined}
      shouldLoop={true}
      data-test="form-v2-submit-button"
    />
  );
};
