import { FC, useContext } from "react";

import { Box, Grid } from "@mui/material";
import { FormType } from "@simplyk/common";

import { Button } from "../../../components/design-system/Button";
import { PaymentErrorProvider } from "../../../components/PaymentElement/PaymentErrorContext";
import { UserAgreement } from "../../../components/UserAgreement/UserAgreement";
import { useFrontendAuctionContext } from "../../../contexts/FrontendAuctionContext";
import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useTranslate } from "../../../hooks/useTranslate";
import { useFormV2Context } from "../context/FormV2Context";
import { useUtmForm } from "../hooks/useUtmForm";
import { FormV2ZeffyLogo } from "../sharedComponents/FormV2ZeffyLogo";

import { useBuyerInformationRenderPosition } from "./hooks/useBuyerInformationRenderPosition";
import { FormV2BuyerInformationSection } from "./sections/FormV2BuyerInformationSection";
import { FormV2BuyerQuestionsSection } from "./sections/FormV2BuyerQuestionsSection";
import { FormV2PaymentSection } from "./sections/FormV2PaymentSection";
import { FormV2SummarySection } from "./sections/SummarySection/FormV2SummarySection";
import { SubmitCommandButton } from "./SubmitButton/SubmitCommandButton";
import { SubmitFreeTicketingButton } from "./SubmitButton/SubmitFreeTicketingButton";
import { SubmitNewBidsButton } from "./SubmitButton/SubmitNewBidsButton";

import { ChevronLeft } from "@/icons/outlined";

export const FormV2Step2: FC = () => {
  const { t } = useTranslate();
  const { isSmallScreen } = useMediaQuery();
  const formV2State = useFormV2Context();
  const { setCurrentStep, isAuction } = formV2State;
  const { isEmbed, displayedFormAmount } = useContext(FrontendFormContext);
  const utm = useUtmForm();
  const { hasAlreadyBid } = useFrontendAuctionContext();

  const isFreeTicket = displayedFormAmount === 0 && formV2State.type === FormType.Ticketing;

  const buyerInfoRenderPosition = useBuyerInformationRenderPosition();

  return (
    <Grid
      container
      sx={(theme) => ({
        width: "100%",
        marginTop: 0,
        paddingX: 2,
        [theme.breakpoints.between(600, theme.breakpoints.values.md)]: {
          paddingX: 3,
        },
        [theme.breakpoints.between(1000, 1350)]: {
          paddingX: 5,
        },
        [theme.breakpoints.between(1350, theme.breakpoints.values.xl)]: {
          paddingX: 10,
        },
        [theme.breakpoints.up("xl")]: {
          paddingX: 20,
        },
        paddingTop: { xs: 2, md: 5 },
        paddingBottom: 0,
        flexGrow: 1,
        alignItems: "flex-start",
        maxWidth: "1116px !important",
        marginX: "auto",
        justifyContent: "center",
      })}
      data-test="ticketing-v2-container-step2"
    >
      {/* Main Column */}
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          position: "relative",
          marginBottom: { md: 5 },
        }}
      >
        {/* Back Button */}
        <Button
          variant="outlined"
          onClick={() => setCurrentStep("selection")}
          startIcon={<ChevronLeft />}
          sx={(theme) => ({
            borderColor: `${theme.palette.border.form.moderate} !important`,
            color: `${theme.palette.text.form.moderate} !important`,
            fill: `${theme.palette.text.form.moderate} !important`,
          })}
        >
          {t("ticketing", "back")}
        </Button>

        <Grid container flexDirection="column" rowSpacing={3} marginTop={0}>
          <PaymentErrorProvider>
            {/* Buyer Information Section (if Before Payment) */}
            {buyerInfoRenderPosition === "before-summary" && <FormV2BuyerInformationSection />}
            {/* Questions Section  */}
            <FormV2BuyerQuestionsSection />
            {/* Summary Section */}
            <FormV2SummarySection />
            {/* Payment Section */}
            <FormV2PaymentSection />
            {/* Buyer Information Section (if After Payment) */}
            {buyerInfoRenderPosition === "after-payment" && <FormV2BuyerInformationSection />}
            {/* Submit Command Button */}
            <Box sx={{ marginTop: 4, marginBottom: 2 }}>
              {!isFreeTicket && !hasAlreadyBid && <SubmitCommandButton />}
              {isAuction && hasAlreadyBid && <SubmitNewBidsButton />}
              {isFreeTicket && <SubmitFreeTicketingButton />}
            </Box>

            <UserAgreement />

            {isSmallScreen && (
              <Box display="flex" justifyContent="center" paddingBlock={2}>
                <FormV2ZeffyLogo utm={utm} formStep={2} />
              </Box>
            )}
          </PaymentErrorProvider>
        </Grid>
      </Grid>

      {/* Icon in the bottom-left */}
      {!isSmallScreen && !isEmbed && <FormV2ZeffyLogo utm={utm} formStep={2} />}
    </Grid>
  );
};
