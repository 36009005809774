import { useContext } from "react";

import { Stack, SxProps } from "@mui/material";
import { formatDateTimeToLocaleString, PaymentMethod } from "@simplyk/common";

import { FrontendFormContext } from "../../../../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../../../../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../../../../../contexts/LocaleContext";

import { Icon } from "@/design-system/Icon";
import { Typography } from "@/design-system/Typography";
import { useTranslate } from "@/hooks/useTranslate";
import { Info } from "@/icons/outlined";

export const AuctionPaymentInfoBox = ({ sx }: { sx?: SxProps }) => {
  const { t } = useTranslate();
  const { locale } = useLocaleContext();
  const { selectedOccurrenceWithRates } = useContext(FrontendTicketingContext);
  const { selectedPaymentMethod } = useContext(FrontendFormContext);

  const selectedOccurrence = selectedOccurrenceWithRates?.occurrence;

  if (selectedPaymentMethod === PaymentMethod.Cash || !selectedOccurrence?.endUtc) {
    return null;
  }

  return (
    <Stack
      columnGap={1}
      direction="row"
      borderRadius={1}
      padding={2}
      sx={{ backgroundColor: (theme) => theme.palette.surface.brand.quiet, ...sx }}
    >
      {/* Info Icon */}
      <Icon size="medium" vibe="text-form-supershy" sx={{ marginTop: 0.25 }}>
        <Info />
      </Icon>

      {/* Info Content */}
      <Stack>
        {/* Info Title */}
        <Typography variant="subtitle1" vibe="text-form-supershy">
          {t("ticketing", "auction.summary.chargeInfo.title")}
        </Typography>

        {/* Info Body */}
        <Typography variant="body2" vibe="text-form-supershy">
          {t("ticketing", "auction.summary.chargeIndo.body", {
            date: formatDateTimeToLocaleString(selectedOccurrence?.endUtc, locale, {
              month: "long",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
          })}
        </Typography>
      </Stack>
    </Stack>
  );
};
