import { useContext, useEffect, useRef } from "react";

import { FrontendFormContext } from "../../../../contexts/FrontendFormContext";

import { useShapes } from "@/features/FormV2/sharedComponents/BackgroundShapes/ShapesContext";

export const useAnimationShapesOnTipChange = () => {
  const { selectedTip, freeTips } = useContext(FrontendFormContext);
  const { drop, up } = useShapes();

  const prevFreeTipsRef = useRef<number | "">(freeTips);
  const previousIsFreeTipRef = useRef<boolean>(Boolean(selectedTip?.isFree));

  useEffect(() => {
    const prevFreeTips = prevFreeTipsRef.current;
    const currentFreeTips = freeTips;
    const previousIsFreeTip = previousIsFreeTipRef.current;
    const currentIsFreeTip = selectedTip.isFree;

    const prevFreeTipsNumber = prevFreeTips === "" ? null : Number(prevFreeTips);
    const currentFreeTipsNumber = currentFreeTips === "" ? null : Number(currentFreeTips);

    // True if free tip is set to zero and previously wasn't zero
    const freeTipSetToZero = currentFreeTipsNumber === 0 && prevFreeTipsNumber !== 0 && currentFreeTips !== "";

    // True if free tip changes from null/zero to a positive amount
    const freeTipBecomesPositive =
      (prevFreeTipsNumber === null || prevFreeTipsNumber === 0) && currentFreeTipsNumber && currentFreeTipsNumber > 0;

    // True if switching from a free tip to a fixed (preselected from dropdown) tip
    const switchToFixedTip = previousIsFreeTip && !currentIsFreeTip;

    if (freeTipSetToZero) {
      drop();
    } else if (freeTipBecomesPositive || switchToFixedTip) {
      up();
    }

    // Update previous values
    prevFreeTipsRef.current = currentFreeTips;
    previousIsFreeTipRef.current = Boolean(currentIsFreeTip);
  }, [freeTips, selectedTip, drop, up]);
};
