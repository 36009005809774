import { formatCanadianRegion, getCountryRegionDataFromOrganizationCountry } from "@simplyk/common";
import { useRouter } from "next/router";

import { OrganizationObject } from "../gql/gql-types";

export const useDefaultCountryRegion = (organization: OrganizationObject) => {
  const router = useRouter();

  const { country: countryQueryParam, region: regionQueryParam, state: stateQueryParam } = router.query;
  return {
    defaultCountry: (countryQueryParam ||
      getCountryRegionDataFromOrganizationCountry(organization.country) ||
      "Canada") as string,
    defaultRegion: (regionQueryParam ||
      stateQueryParam ||
      formatCanadianRegion(organization.region) ||
      "Quebec") as string,
  };
};
