import { ChangeEvent, FC, useContext } from "react";

import { Grid } from "@mui/material";
import { formatDateToFullDate, FormType, OrganizationCountry } from "@simplyk/common";

import { Checkbox } from "../../../../../components/design-system/Checkbox";
import { DiscountCodeField } from "../../../../../components/DiscountCodeField/DiscountCodeField";
import { AmplitudeEvents } from "../../../../../constants/amplitude";
import { FrontendTicketingContext } from "../../../../../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../../../../../contexts/LocaleContext";
import { useAmplitude } from "../../../../../hooks/amplitude/useAmplitude";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { GHOST_PREVIEW_OPACITY } from "../../../../LiveFormEditor/LivePreview/ghostPreview.constant";
import { useIsGhostPreview } from "../../../../LiveFormEditor/LivePreview/useIsGhostPreview";
import { useFormV2Context } from "../../../context/FormV2Context";

export const TicketingV2RenewalAndDiscountsSection: FC = () => {
  const { locale } = useLocaleContext();
  const { t } = useTranslate();
  const { form, formCategory } = useFormV2Context(FormType.Ticketing);
  const { hasDiscountCodes, selectedOccurrenceId, membershipRenewalBuyerInfo, ticketing } =
    useContext(FrontendTicketingContext);
  const { logAmplitudeEventCallback } = useAmplitude();
  const isDiscountCodeGhostPreview = useIsGhostPreview("TicketingV2DiscountCode");

  const { expirationDate, uniquelySelectedTicketIndex, shouldProposeAutomaticRenewal } =
    membershipRenewalBuyerInfo || {};

  const handleAutomaticRenewalChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    logAmplitudeEventCallback(AmplitudeEvents.TicketClickGeneralInput, {
      label: "automaticRenewal",
    });
    form.setValue(`ticketsPurchased.${uniquelySelectedTicketIndex || 0}.automaticRenewal`, checked);
  };

  const automaticRenewalChecked = form.watch(`ticketsPurchased.${uniquelySelectedTicketIndex || 0}.automaticRenewal`);

  if (!shouldProposeAutomaticRenewal && !hasDiscountCodes) {
    return null;
  }

  return (
    <>
      {shouldProposeAutomaticRenewal && (
        <Grid item marginBottom={2}>
          <Checkbox
            checked={automaticRenewalChecked}
            onChange={handleAutomaticRenewalChange}
            label={t("ticketing", "automaticRenewal", {
              expirationDate: expirationDate ? formatDateToFullDate(expirationDate, locale, "utc") : null,
            })}
            data-test="automatic-renewal-checkbox"
            size="small"
            style={{ display: "flex" }}
          />
        </Grid>
      )}

      {hasDiscountCodes && (
        <Grid
          item
          marginBottom={2}
          sx={{
            ...(isDiscountCodeGhostPreview && {
              opacity: GHOST_PREVIEW_OPACITY,
            }),
          }}
        >
          <DiscountCodeField
            setValue={form.setValue}
            register={form.register}
            discountIdName="discountId"
            ticketingId={ticketing.id}
            formCategory={formCategory}
            organizationCountry={ticketing.organization?.country as OrganizationCountry}
            selectedOccurrenceId={selectedOccurrenceId}
          />
        </Grid>
      )}
    </>
  );
};
