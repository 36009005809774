import { useContext } from "react";

import { PaymentMethod } from "@simplyk/common";

import { useFrontendAuctionContext } from "../../../../contexts/FrontendAuctionContext";
import { FrontendFormContext } from "../../../../contexts/FrontendFormContext";
import { usePreviewContext } from "../../../LiveFormEditor/LivePreview/context/PreviewContext";

type RenderPosition = "before-summary" | "after-payment" | null;

export const useBuyerInformationRenderPosition = (): RenderPosition => {
  const { displayExpressCheckout, selectedPaymentMethod, isExpressCheckoutLoading, displayedFormAmount } =
    useContext(FrontendFormContext);
  const { hasAlreadyBid } = useFrontendAuctionContext();
  const { isPreview } = usePreviewContext();

  if (hasAlreadyBid) {
    return null;
  }

  if ((!isExpressCheckoutLoading && !displayExpressCheckout) || displayedFormAmount === 0 || isPreview) {
    return "before-summary";
  }

  if (selectedPaymentMethod && selectedPaymentMethod !== PaymentMethod.ApplePayOrGooglePay) {
    return "after-payment";
  }

  return null;
};
