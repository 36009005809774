import { FC } from "react";

import { Stack } from "@mui/material";
import { FormType } from "@simplyk/common";

import { PaymentProcessorSummary } from "../../../../../components/PaymentProcessor/PaymentProcessorSummary";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { useIsGhostPreview } from "../../../../LiveFormEditor/LivePreview/useIsGhostPreview";
import { useFormV2Context } from "../../../context/FormV2Context";
import { FormV2Step2SectionWrapper } from "../FormV2Step2SectionWrapper";
import { PublicDonationCheckbox, usePublicDonationCheckboxRenderPosition } from "../PublicDonationCheckBox";

import { InHonour } from "./InHonour";
import { TicketingV2RenewalAndDiscountsSection } from "./TicketingV2RenewalAndDiscountsSection";

import { AuctionPaymentInfoBox } from "@/features/FormV2/Step2/sections/SummarySection/AuctionPaymentInfoBox";

export const FormV2SummarySection: FC = () => {
  const { t } = useTranslate();
  const { form, type, formObject, isAuction } = useFormV2Context();
  const isSummaryGhostPreview = useIsGhostPreview("TicketingV2Step2SummarySection");
  const publicDonationCheckboxPosition = usePublicDonationCheckboxRenderPosition();

  return (
    <FormV2Step2SectionWrapper title={t("ticketing", "summary")} isGhostPreview={isSummaryGhostPreview}>
      <Stack spacing={1}>
        {publicDonationCheckboxPosition === "summary-section" && <PublicDonationCheckbox />}
        {type === FormType.DonationForm && formObject.allowInHonour && <InHonour />}

        {type === FormType.Ticketing && <TicketingV2RenewalAndDiscountsSection />}

        {isAuction && <AuctionPaymentInfoBox />}

        <PaymentProcessorSummary control={type === FormType.Ticketing ? form.control : undefined} />
      </Stack>
    </FormV2Step2SectionWrapper>
  );
};
